function Install() {

    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    var domain = window.location.protocol + '//' + window.location.host;

    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);

        } catch (err) {

        }
    };

    return (<div>  <div class="add-to-home"
        style={{
            display: "block"
        }}
    >
        {/* <div class="text-right browser-preview f12"
        >Preview in browser</div> */}
        <div class="logo-name-container"
            style={{
                backgroundImage: "url(./logo2.png)",

            }}
        >

            {(iOS && iOSSafari) ? (<><div>
                SODO66<p>Cài đặt ngay bên dưới</p> 
                  </div>

                <div class="homescreen-text">Để cài đặt chọn
                    <div class="icon-addToHome sprite-mobile"
                        style={{
                            backgroundImage: "url(./images/mobile-sprite.png)"
                        }}
                    >
                    </div> và chọn tiếp     <br /> Thêm vào MH chính

                    <div class="icon-homePointer sprite-mobile"
                        style={{
                            backgroundImage: "url(./images/mobile-sprite.png)"
                        }}></div>
                </div></>) : <div>
                Vui lòng sử dụng IPHONE hoặc IPAD truy cập
                <p >{domain} </p>
                bằng trình duyệt Safari để cài đặt!
            </div>
            }

        </div>

    </div>

    </div>)
}

export default Install;