import './App.css';
import Iframe from 'react-iframe'
import useWindowDimensions from './dime';
import Install from './Install';



function App() {
 

  const { height, width } = useWindowDimensions();


  var ua = window.navigator.userAgent;
  var ua = window.navigator.userAgent;
  var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  var webkit = !!ua.match(/WebKit/i);
  var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
  var redirected = false;

  
  //linksodo66
  var link = atob("aHR0cHM6Ly93d3cuczk5OWIuY29tLz9pbnZpdGVDb2RlPTczMDY5NDkxJnJlZ0FnZW50SnVtcEZsYWc9MQ==")

  var isHome = false;
  if (navigator.userAgent.indexOf('iPhone') != -1) {//test for other iOS devices?
    if (window.navigator.standalone == true && iOS && iOSSafari) {
      isHome = true;
      if (redirected === false) {
        redirected = true
      }
    } else {
      isHome = false;
      //show message telling user to add to their home screen...
    }
  } else {
    isHome = false;
    //show message that this must be run on device X/Y/Z...
  }


  return (
    <div className="App">

      <div class={isHome == false ? "demo blur" : ""}>
        <div id='idiv'>
          <Iframe url={link}
            width={width}
            height={height}
          />
        </div>
      </div>

      {isHome == false && <Install />}

      {/* <AddToHomeScreen
        appId='Add-to-Homescreen React Customized Guidance Images Example'
        customPromptPlatformDependencies={{
          chromium: {
            images: [
              {
                src: 'images/guidance/chromium-example.png',
                alt: 'Guide to install application on home screen.'
              }
            ]
          },
          edge: {
            images: [
              {
                src: 'images/guidance/chromium-example.png',
                alt: 'Guide to install application on home screen.'
              }
            ]
          },
          iphone: {
            images: [
              {
                src: 'images/guidance/iphone-example.png',
                alt: 'Guide to install application on home screen.'
              }
            ]
          },
          ipad: {
            images: [
              {
                src: 'images/guidance/ipad-example.png',
                alt: 'Guide to install application on home screen.'
              }
            ]
          },
          firefox: {
            images: [
              {
                src: 'images/guidance/firefox-example.png',
                alt: 'Guide to install application on home screen.'
              }
            ]
          },
          samsung: {
            images: [
              {
                src: 'images/guidance/firefox-example.png',
                alt: 'Guide to install application on home screen.'
              }
            ]
          },
          opera: {
            images: [
              {
                src: 'images/guidance/opera-example.png',
                alt: 'Guide to install application on home screen.'
              }
            ]
          }
        }}
      /> */}

    </div>
  );
}

export default App;
